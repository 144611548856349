.constructor_area {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    column-gap: 1rem;
}

.constructor_dropdown_area {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    padding-bottom: 1.5rem;
}

.constructor_dropdown_area select {
    width: 60%;
}

.constructor_slider_area {
    padding-bottom: 1.5rem;
}

.constructor_dropdown_title {
    width: 48%;
}

.constructor_selector_p50_z0 {
    width: 48%;
    z-index: 0;
}

.constructor_total_card_area {
    width: 90%;
    margin: 0 auto 1rem;
}

.constructor_total_card {
    border-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, .125);
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
}

.constructor_colored_side {
    background-color: var(--color_main_light);
    color: black;
}

.constructor_colored_side_dark {
    background-color: #575757;
}

.constructor_total_card_param_title {
    font-size: 0.9rem;
    padding-top: 1rem;
    padding-left: 1rem;
    text-transform: uppercase;
}

.constructor_total_card_param_value {
    padding-left: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
}

.constructor_total_card_buy_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 2rem;
}

.constructor_total_card_agree {
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
}

.constructor_total_card_agree .text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    text-decoration: none;
    color: black;
    vertical-align: super;
}

.constructor_total_card_agree .text_dark {
    color: white;
}

.constructor_total_card_agree .text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.constructor_button_buy {
    min-width: 6rem;
}

.constructor_slider_title {
    font-size: 1.1rem;
}

.constructor_proto_link_to_faq {
    padding-left: 1rem;
    color: gray;
    text-decoration: none;
    font-size: 0.9rem;
}

.constructor_proto_link_to_faq_dark {
    color: var(--color_main_light);
}

.constructor_proto_link_to_faq:hover {
    text-decoration: underline;
}

.constructor_about {
    text-decoration: none;
    font-style: italic;
    margin-left: 0.5rem;
    color: black;
}

.constructor_about_dark {
    color: white;
}

.constructor_about:hover {
    text-decoration: underline;
}

.constructor_manipulators_area {
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
}

@media screen and (min-width: 48rem) {
    .constructor_area {
        flex-direction: row;
    }

    .constructor_total_card_area {
        width: 50%;
        margin-bottom: auto;
        padding: 1rem;
    }

    .constructor_manipulators_area {
        width: 50%;
    }
}

@media screen and (min-width: 64rem) {
    .constructor_total_card_area {
        width: 45%;
    }

    .constructor_manipulators_area {
        width: 45%;
    }
}

@media screen and (min-width: 75rem) {
    .constructor_total_card_area {
        width: 45%;
    }

    .constructor_manipulators_area {
        width: 45%;
    }
}