.profile_option {
    font-weight: 600;
    margin-right: 1rem;
    display: inline-block;
    width: 30%;
}

.profile_option_wide {
    font-weight: 600;
    margin-right: 1rem;
    display: inline-block;
    width: 100%;
}

.profile_save_button {
    box-shadow: none;
    margin-left: 1rem;
    font-size: 1rem;
}

.profile_password_area {
    border: 1px solid transparent;
    padding-top: 1rem;
    margin-bottom: 1rem;
}

.profile_password_area_active {
    border: 1px solid red;
    padding-top: 1rem;
    margin-bottom: 1rem;
}

.profile_popup_partner {
    display: block;
    margin: 1rem auto;
    padding: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    color: black;
    text-decoration: none;
    border: solid 1px var(--color_main_light);
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
}

.profile_popup_partner_dark {
    background-color: #908D96;
}

.profile_popup_partner:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.profile_popup_partner_title {
    font-weight: 600;
}

.profile_popup_partner_currencies {
    displey: flex;
}

.profile_popup_partner_currencies img {
    max-height: 2rem;
}

.profile_popup_partner_description {
    font-size: 1rem;
}

.cryptocloud_paragraph_text input {
    height: 2rem;
    width: 6rem;
    font-size: 1.2rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.monero_qr {
    display: flex;
    justify-content: center;
    margin: 1rem;
    border-radius: 0.2rem;
}

.monero_qr .monero_qr_image {
    width: 80%;
    border-radius: 0.2rem;
}

.monero_address {
    display: block;
    overflow-wrap: break-word;
    margin: 1rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid;
    border-radius: 0.2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.support_contacts_wrapper {
    font-size: 1rem;
}

.support_contacts_wrapper h1 {
    font-size: 1.5rem;
    font-weight: bold;
    width: 90%;
    margin: 0 auto;
}

.support_contacts_wrapper p {
    font-size: 1rem;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
}