.index_learn_more_slogan {
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.index_learn_more_text {
    font-weight: 300;
    font-size: 1rem;
    padding-top: 0;
}

.index_prices_button {
    line-height: 50px;
    align-self: center;
    width: 50%;
}

.index_pon_letters {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-evenly;
    font-family: monospace;
}

.index_pon_letter {
    font-size: 5rem;
    line-height: 6rem;
    font-weight: 600;
    color: #f5f5f5;
    position: relative;
    text-align: center;
    width: 120px;
    text-shadow: 5px 5px #B3CDFB;
}

.index_pon_letter_dark {
    text-shadow: none;
}

.index_pon_meaning {
    text-shadow: 1px 1px 2px pink;
    font-size: 1.2rem;
    line-height: 4.75rem;
    width: 120px;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    bottom: 10%;
    margin: 0 auto;
    color: var(--color_nice_brown);
    font-weight: 600;
    text-decoration: none;
    user-select: none;
}

.index_pon_meaning_dark {
    text-shadow: 2px 2px 3px black;
    color: #f59847;
}

.index_we_use_ovpn_background {
    display: flex;
    align-items: center;
    background-color: rgba(251, 238, 224, 1);
}

.index_we_use_ovpn_background_dark {
    background: var(--color_main_dark);
}

.index_pros_area {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.index_pros_area_dark {
    color: white;
}

.index_pro {
    height: 100%;
}

.index_platform_background {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgba(251, 238, 224, 1);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.index_platform_background_dark {
    background: var(--color_main_dark);
    color: white;
}

.index_platform_area {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.index_platform_slogan {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
}

.index_platform_text {
    text-align: center;
    word-wrap: break-word;
    font-size: 0.9rem;
}

.index_platform {
    display: flex;
    justify-content: center;
    align-items: center;
}

.index_platform img {
    height: 3rem;
}

.index_steps {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.index_steps_dark {
    background: var(--color_background_dark);
    color: white;
}

.index_steps_slogan {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.index_step {
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 0.8rem;
    height: 100%;
    background-color: white;
}

.index_step_dark {
    background-color: var(--color_main_dark);
}

.index_step_image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.index_step_image img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 0.8rem;
}

.index_step_slogan {
    font-weight: bold;
    margin: 1rem;
}

.index_step_description {
    font-size: 1rem;
    margin: 1rem;
}

@media screen and (min-width: 48rem) {
    .index_we_use_ovpn_background {
        height: 11rem;
    }

    .index_platform_text {
        text-align: center;
        word-wrap: break-word;
        font-size: 1.1rem;
    }
}