.about_section {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.about_section_dark {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: white;
    background: var(--color_main_dark);
}

.about_section_white_letters {
    color: #cec7c7;
}

.about_section_colored {
    background-color: var(--color_main_light);
}

.about_grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    column-gap: 2rem;
    row-gap: 1rem;

}

.about_grid2 {
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    row-gap: 1rem;
}

.about_who_we_are_title {
    text-align: left;
    font-weight: 600;
    font-size: 1.6rem;
}

.about_who_we_are_container {
    border-radius: 0.8rem;

}

.about_who_we_are_1 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    text-align: justify;
}

.about_who_we_are_image {
    display: flex;
}

.about_who_we_are_image img {
    border-radius: 1rem;
}

.about_side_sector_big {
    width: 90%;
    margin: 0 auto;
}

.about_side_sector {
    width: auto;
    align-self: baseline;
}

@media screen and (min-width: 48rem) {
    .about_grid, .about_grid2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .about_side_sector_big {
        width: 65%;
    }

    .about_side_sector {
        width: 35%;
    }
}