:root {
    --faq_color_menu_bg_light: white;
    --faq_color_menu_font_light: black;

    --faq_color_menu_hover_bg_light: #999;
    --faq_color_menu_hover_font_light: white;

    --faq_color_menu_active_bg: #999;
    --faq_color_menu_active_font: white;

    --faq_color_menu_header_bg: burlywood;
    --faq_color_menu_header_font: black;

    --faq_color_menu_bg_dark: #575757;
    --faq_color_menu_font_dark: white;
}

.faq_cont {
    display: block;
}

.faq_menu_canvas {
    width: 100%;
    border-radius: 0.4rem;
    background-color: white;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    margin-bottom: 1rem;
    height: 42rem;
}

.faq_menu_hidable {
    display: none;
    background-color: white;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    margin-bottom: 1rem;
    height: 0;
}

.faq_menu_canvas_dark a {
    background-color: var(--faq_color_menu_bg_dark);
    color: var(--faq_color_menu_font_dark);
}

.faq_menu_canvas .faq_menu_header {
    background-color: var(--faq_color_menu_header_bg);
    color: var(--faq_color_menu_header_font);
}

.faq_menu_hidable .faq_menu_header {
    background-color: var(--faq_color_menu_header_bg);
    color: var(--faq_color_menu_header_font);
}

.faq_menu_canvas .faq_active {
    background-color: var(--faq_color_menu_active_bg);
    color: var(--faq_color_menu_active_font);
}

.faq_menu_hidable .faq_active {
    background-color: var(--faq_color_menu_active_bg);
    color: var(--faq_color_menu_active_font);
}

.faq_content {
    margin: 0 auto 2rem;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0.4rem;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
}

.faq_content_dark {
    background-color: var(--color_main_dark);
    color: white;
}

.faq_menu_header {
    display: block;
    padding: 0.6rem 0.6rem 0.6rem 1rem;
    text-decoration: none;
    color: black;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, .125);
    background: burlywood;
}

.faq_menu_header:hover {
    font-weight: 600;
    cursor: pointer;
    color: white;
    background-color: #999;
}

.faq_menu_item {
    display: block;
    text-decoration: none;
    background-color: var(--faq_color_menu_bg_light);
    color: var(--faq_color_menu_font_light);
    font-size: 1rem;
    padding: 0.6rem 0.6rem 0.6rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, .125);
}

.faq_menu_item:hover {
    font-weight: 600;
    cursor: pointer;
    color: var(--faq_color_menu_hover_font_light);
    background: var(--faq_color_menu_hover_bg_light);
}

.faq_protocol_info {
    font-size: 1rem;
}

.faq_title {
    padding-top: 1rem;
    font-weight: 600;
}

.faq_speech {
    font-size: 1rem;
}

.faq_image_area {
    padding: 0;
    display: flex;
}

.faq_image_area img {
    border-radius: 0.5rem;
    width: 95%;
}

.faq_font_size {
    font-size: 1rem;
}

.faq_font_size h1 {
    font-size: 1.5rem;
}

.faq_font_size h2 {
    font-size: 1.2rem;
}

.faq_font_size h3 {
    font-size: 1rem;
}

.faq_terms_conditions div {
    padding: 1rem;
}

.faq_body_internal {
    padding: 1rem;
}

.faq_step {
    border-radius: 1rem;
    background-color: #f5f5f5;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.faq_step_dark {
    background-color: var(--color_background_dark);
}

.faq_step ul li a{
    color: #66bb6a;
}

.faq_step ul li a:hover {
    color: burlywood;
}

.faq_image {
    border-radius: 1rem;
    align-self: baseline;
}

.faq_step_title {
    font-size: 1.3rem;
    font-weight: 600;
}

.faq_step_body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.faq_note {
    font-style: italic;
    font-size: 1rem;
}

.faq_note a{
    color: #66bb6a;
}

.faq_note a:hover {
    color: burlywood;
}

.faq_list_download {
    padding-left: 1rem;
}

@media screen and (min-width: 48rem) {
    .faq_cont {
        display: flex;
        justify-content: space-between;
    }

    .faq_menu_canvas {
        display: none;
        width: 25%;
        border-radius: 0.4rem;
    }

    .faq_content {
        margin-top: 0;
        width: 70%;
        border-radius: 0.4rem;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    }

    .faq_image_area {
        padding: 0.5rem;
        display: flex;
    }

    .faq_image_area img {
        border-radius: 0.5rem;
        width: 80%;
    }

    .faq_menu_hidable {
        display: block;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
        width: 25%;
        font-size: 1rem;
        margin-bottom: 0;
    }
}