
.certificates_title {
    margin: 1rem;
    margin-left: 2rem;
}
.certificates_main {
    margin: 0.3rem auto;
    width: 95%;
    border: 1px solid rgba(0, 0, 0, .125);
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
}

.certificates_error_cert {
    background-color: #F08080;
    border: 3px solid #F08080;
}

.certificates_content {
    display: flex;
    flex-direction: column;
    border-radius: 0.8rem;
    background-color: white;
}

.certificates_content_dark {
    background-color: #575757;
}

.certificates_side1 {
    width: 100%;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    color: rgb(66, 40, 0);
    background-color: var(--color_main_light);
}

.certificates_side2 {
    background-color: white;
    width: 100%;
}

.certificates_side2_dark {
    background-color: #575757;
    color: white;
    width: 100%;
}

.certificates_param {
    font-size: 0.9rem;
    padding-top: 0.1rem;
    padding-left: 1rem;
    text-transform: uppercase;
}

.certificates_first_param {
    padding-top: 1rem;
}

.certificates_name {
    padding-left: 1rem;
}

.certificates_buttons {
    width: 100%;
    display: flex;
    justify-content: right;
    font-size: 0.9rem;
    color: gray;
}

.certificates_buttons_dark {
    color: white;
}

.certificates_button {
    padding: 0.5rem;
    padding-top: 0;
    cursor: pointer;
}

.certificates_button:hover {
    text-decoration: underline;
}

.certificates_settings {
    background-color: white;
    max-height: 60rem;
    transition: max-height 0.5s ease-in;
    overflow: hidden;
}

.certificates_invisible_settings {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

.certificates_settings_dark {
    background-color: #575757;
}

.certificates_settings_info {
    font-size: 1rem;
    padding: 1rem;
    padding-top: 0rem;
    color: gray;
}

.certificates_settings_info_dark {
    color: white;
}

.certificates_slider {
    background-color: red;
}

.certificates_card_buttons_area {
    width: 100%;
    display: flex;
    justify-content: right;
}

.certificates_title_bw {
    padding-bottom: 1rem;
}

.certificates_card_ip {
    padding-left: 1rem;
    font-size: 0.8rem;
    display: inline-block;
}

.certificates_card_setting_row {
    padding: 1rem;
    padding-bottom: 0rem;
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.certificates_option {
    font-weight: bold;
    margin-right: 1rem;
    margin-bottom: 0.4rem;
}

.certificates_alert {
    position: fixed;
    top: 50%;
    left: 50%;
    color: green;
    background: white;
    text-align: center;
    font-size: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.certificates_dual_ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid #fcf;
    border-color: #fcf transparent #fcf transparent;
    animation: certificates_dual_ring 1.2s linear infinite;
    margin: 1rem;
}

@keyframes certificates_dual_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes certificates_dual_ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 48rem) {
    .certificates_content {
        display: flex;
        flex-direction: row;
    }

    .certificates_side1 {
        width: 38%;
        border-top-right-radius: 0rem;
    }

    .certificates_side2 {
        width: 62%;
        border-top-right-radius: 0.8rem;
    }
}