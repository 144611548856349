.enter_canvas {
    margin: 2rem auto 0;
    width: 99%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 0.3rem;
    padding-bottom: 1.5rem;
}

.enter_canvas_dark {
    background-color: var(--color_main_dark);
    color: white;
}

.enter_title {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    text-transform: uppercase;
}

.enter_fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.enter_captcha_box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.enter_short {
    width: 40%;
}

.enter_captcha_ans_area {
    display: flex;
    justify-content: space-evenly;
}

.enter_captcha_title {
    font-size: 1rem;
    text-align: center;
}

.enter_captcha_eval {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.enter_field_area {
    width: 90%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
    margin-bottom: 1.2rem;
}

.enter_field_area img {
    height: 1.2rem;
    width: 1.2rem;
    align-self: center;
    padding: 0 0 0 1rem;
}

.enter_field {
    height: 2.5rem;
    width: 90%;
    font-size: 1.2rem;
    border: none;
    padding-left: 1.5rem;
    padding-right: 1rem;
    background-color: transparent;
}

.enter_field_dark {
    color: white;
}

.enter_field:focus {
    outline: none;
}

.enter_button {
    display: block;
    width: 10rem;
    text-align: center;
    border: 1px solid;
    padding: 0.5rem;
    text-decoration: none;
    color: black;
    margin: 2rem auto 0;
}

.enter_button_dark {
    color: white;
    border: 1px solid white;
}

.enter_switch_button {
    display: block;
    width: 8rem;
    text-align: center;
    text-decoration: none;
    margin: 1.5rem auto 0;
    color: black;
    font-size: 1rem;
}

.enter_switch_button_dark {
    color: white;
}

.enter_alert {
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    color: red;
}

.enter_alert_ok {
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    color: green;
}

@media screen and (min-width: 48rem) {
    .enter_canvas {
        width: 30rem;
    }

    .enter_field_area {
        width: 80%;
    }
}