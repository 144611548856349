.prices_page_background {
    display: flex;
    align-items: center;
    background-color: rgba(251, 238, 224, 1);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.prices_page_background_dark {
    background: var(--color_main_dark);
}

.prices_constructor_title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-top: 2rem;
    text-align: center;
}