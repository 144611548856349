.common_invisible {
    display: none;
}

.body_dark {
    background: var(--color_background_dark);
}

.common_filler {
    min-height: 800px;
}

.common_fancy_button {
    background-color: var(--color_main_light);
    border: 2px solid var(--color_nice_brown);
    border-radius: 30px;
    box-shadow: var(--color_nice_brown) 4px 4px 0 0;
    color: var(--color_nice_brown);
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 0 18px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.common_fancy_button_dark {
    box-shadow: none;
    border-color: white;
}

.common_fancy_button:hover {
    background-color: #fff;
}

.common_fancy_button:active {
    box-shadow: var(--color_nice_brown) 2px 2px 0 0;
    transform: translate(2px, 2px);
}

.common_container {
    width: 90%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-left: auto;
    margin-right: auto;
}

.common_grid_2, .common_grid_3 {
    align-items: center;
    box-sizing: inherit;
    display: grid;
    column-gap: 2rem;
    row-gap: 2rem;
}

.common_area_mr {
    text-align: justify;
    margin: 1rem 3rem 1rem 3rem;
    padding-bottom: 1rem;
}

.common_section {
    text-align: left;
    align-items: center;
    flex-direction: row;
    justify-content: start;
    background-color: rgba(244, 245, 240, 1);
}

.common_section_dark {
    background: var(--color_background_dark);
    color: white;
}

.common_paragraph {
    text-align: justify;
    font-size: 1rem;
    width: 90%;
    margin: 1rem auto;
}

.common_paragraph_title {
    font-weight: 600;
    margin-right: 1rem;
}

.common_title {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
}

.common_slider {
    width: 100%;
    background-color: transparent;
}

.common_protocol_title {
    font-weight: 600;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.common_image {
    display: flex;
    border-radius: 2rem;
    align-self: baseline;
}

.common_slogan {
    font-weight: 800;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.common_text {
    font-weight: 300;
    font-size: 1rem;
}

.common_area{
    text-align: left;
}

.common_area ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 1rem;
}

.common_area li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 600;
}

.common_phrase {
    font-size: 1rem;
    padding-top: 0.5rem;
}

.common_panorama_name {
    font-size: var(--font-size-h1);
    font-weight: 600;
}

.common_panorama_slogan {
    font-size: var(--font-size-h2);
    line-height: var(--line-height-default);
}

.common_panorama_button {
    line-height: 50px;
    min-width: 12rem;
}

.common_panorama_image {
    border-radius: 2rem;
    align-self: baseline;
}

.common_image_br_05 {
    border-radius: 2rem;
    width: 100%;
}

.common_h3_slogan {
    display: flex;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 200;
    text-align: center;
    border-radius: 15px;
    color: var(--color_nice_brown);
}

.common_h3_slogan_dark {
    color: white;
}

.common_mobile_full_width_container {
    width: 100%;
    padding: 0;
}

.common_button_mr {
    margin-top: 1rem;
}

.common_footer_header_gap {
    padding: 1rem;
}


.selector {
    font-size: 1rem;
    z-index: 2;
    background-color: var(--color_main_light);
    border: 2px solid var(--color_nice_brown);
    box-shadow: var(--color_nice_brown) 4px 4px 0 0;
    color: var(--color_nice_brown);
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.selector_dark {
    box-shadow: none;
}

.agree_checkbox {
    display: inline;
}

.agree_checkbox .switchAgree {
    display: inline-block;
    height: 28px;
    position: relative;
    width: 60px;
}

.agree_checkbox .switchAgree input {
    display: none;
}

.agree_checkbox .sliderAgree {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .1s;
}

.agree_checkbox .sliderAgree:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 20px;
    left: 4px;
    position: absolute;
    transition: .1s;
    width: 20px;
}

.agree_checkbox input:checked + .sliderAgree {
    background-color: #66bb6a;
}

.agree_checkbox input:checked + .sliderAgree:before {
    transform: translateX(32px);
}

.agree_checkbox .sliderAgree.round {
    border-radius: 25px;
}

.agree_checkbox .sliderAgree.round:before {
    border-radius: 50%;
}

@media screen and (min-width: 48rem) {
    .common_container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 45rem;
        margin-left: auto;
        margin-right: auto;
    }

    .common_grid_2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .common_grid_3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .common_h3_slogan {
        display: flex;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 200;
        text-align: center;
        border-radius: 15px;
    }

    .common_mobile_full_width_container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        width: 47rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 64rem) {
    .common_container {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        width: 58rem;
        margin-left: auto;
        margin-right: auto;
    }

    .common_mobile_full_width_container {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        width: 58rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 75rem) {
    .common_container {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        width: 67rem;
        margin-left: auto;
        margin-right: auto;
    }

    .common_mobile_full_width_container {
        padding-left: 4.25rem;
        padding-right: 4.25rem;
        width: 67rem;
        margin-left: auto;
        margin-right: auto;
    }
}