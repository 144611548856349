:root {
  --color_background_light: #F4F5F0;
  --color_background_dark: #282828;

  --color_main_light: rgb(251, 238, 224);
  --color_main_dark: #3F3F3F;

  --color_nice_brown: #422800;
}


:root {
    --font-size-default: 1rem;
    --font-size-h1: 2rem;
    --font-size-h2: 1.7rem;
    --line-height-default: 1.5;
}

html {
    box-sizing: border-box;
}

body {
    background-color: var(--color_background_light);
    color: black;
    font-family: monospace;
    font-size: var(--font-size-default);
    font-weight: 400;
    line-height: var(--line-height-default);
    margin: 0 auto;
}

h1 {
    font-size: var(--font-size-h1);
}

ul {
    list-style: none;
}

img, svg {
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

@media screen and (min-width: 48rem) {
    :root {
        --font-size-default: 1.1rem;
        --font-size-h1: 1.9rem;
        --line-height-default: 1.5;
        --main-section-column-gap: 1rem;
    }
}

@media screen and (min-width: 64rem) {
    :root {
        --font-size-default: 1.2rem;
        --font-size-h1: 2.2rem;
        --line-height-default: 1.5;
        --main-section-column-gap: 1rem;
    }
}

@media screen and (min-width: 75rem) {
    :root {
        --font-size-default: 1.3rem;
        --font-size-h1: 2.5rem;
        --line-height-default: 1.5;
        --main-section-column-gap: 1rem;
    }
}