:root {
    --footer_color_background_light: var(--color_main_light);
    --footer_color_background_dark: var(--color_main_dark);

    --footer_color_font_light: var(--color_nice_brown);
    --footer_color_font_dark: white;

    --foter_color_top_boder: gray;
}

.footer_area {
    min-height: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: var(--footer_color_background_light);
    color: var(--footer_color_font_light);
    border-top: 0.2rem solid var(--foter_color_top_boder);
}

.footer_area_dark {
    background: var(--footer_color_background_dark);
    color: var(--footer_color_font_dark);
}

.footer_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer_col {
    width: 100%;
    padding: 0 15px;
}

.footer_col_header {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.footer_col_elements a {
    font-size: 1rem;
    text-decoration: none;
    font-weight: 300;
    display: block;
    transition: all 0.3s ease;
    margin-bottom: 0.5rem;
    color: var(--footer_color_font_light);
}

.footer_col_elements_dark a {
    color: var(--footer_color_font_dark);
}

.footer_col_elements a:hover {
    text-decoration: underline;
}

.footer_logo {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
}

.footer_slogan {
    font-size: 1rem;
}

.footer_pon {
    font-size: 1rem;
    padding-top: 1rem;
    font-weight: 600;
}

.footer_currencies {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.footer_currency {
    width: 18%;
    display: flex;
    justify-content: center;
}

.footer_currency img {
    max-height: 2rem;
}

@media screen and (min-width: 48rem) {
    .footer_col {
        width: 22%;
    }
}