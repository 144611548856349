:root {
    --cabinet_color_menu_bg_light: white;
    --cabinet_color_menu_font_light: black;

    --cabinet_color_menu_hover_bg_light: #f8f9fa;
    --cabinet_color_menu_hover_font_light: black;

    --cabinet_color_menu_active_bg_light: #999;
    ;
    --cabinet_color_menu_active_font_light: white;


    --cabinet_color_menu_bg_dark: var(--color_main_dark);
    --cabinet_color_menu_font_dark: white;

    --cabinet_color_menu_hover_bg_dark: #575757;
    --cabinet_color_menu_hover_font_dark: white;

    --cabinet_color_menu_active_bg_dark: #575757;
    ;
    --cabinet_color_menu_active_font_dark: white;
}

.cabinet_title {
    font-size: 1.5rem;
    font-weight: bold;
}

.cabinet_area {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 1rem;
}

.cabinet_menu_hidable {
    display: none;
    background-color: white;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    margin-bottom: 1rem;
    height: 0px;
}

.cabinet_menu_mobile {
    background-color: white;
    width: 100%;
    margin-bottom: 1rem;
    height: 240px;
}

.cabinet_menu_element {
    display: block;
    padding: 1rem;
    text-decoration: none;
    background-color: var(--cabinet_color_menu_bg_light);
    color: var(--cabinet_color_menu_font_light);
    border: 1px solid rgba(0, 0, 0, .125);
}

.cabinet_menu_hidable_dark .cabinet_menu_element {
    background-color: var(--cabinet_color_menu_bg_dark);
    color: var(--cabinet_color_menu_font_dark);
}

.cabinet_menu_hidable_dark .cabinet_menu_element:hover {
    background-color: var(--cabinet_color_menu_hover_bg_dark);
    color: var(--cabinet_color_menu_hover_font_dark);
}

.cabinet_menu_hidable_dark .cabinet_menu_element_active {
    background-color: var(--cabinet_color_menu_active_bg_dark);
    color: var(--cabinet_color_menu_active_font_dark);
}

.cabinet_menu_mobile_dark {
    background-color: var(--color_background_dark);
}

.cabinet_menu_mobile_dark .cabinet_menu_element {
    background-color: var(--color_main_dark);
    color: white;
}

.cabinet_menu_mobile_dark .cabinet_menu_element:hover {
    background-color: #575757;
    color: white;
}

.cabinet_menu_mobile_dark .cabinet_menu_element_active {
    background-color: #575757;
    color: white;
}

.cabinet_body {
    background-color: white;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
    border: 1px solid rgba(0, 0, 0, .125);
    min-height: 700px;
    border-radius: 0.5rem;
}

.cabinet_body_dark {
    background-color: var(--color_main_dark);
    color: white;
}

.cabinet_menu_element:hover {
    background-color: var(--cabinet_color_menu_hover_bg_light);
    color: var(--cabinet_color_menu_hover_font_light);
}

.cabinet_menu_element_active {
    background-color: var(--cabinet_color_menu_active_bg_light);
    color: var(--cabinet_color_menu_active_font_light);
}

.cabinet_menu_element_active:hover {
    background-color: var(--cabinet_color_menu_active_bg_light);
    color: var(--cabinet_color_menu_active_font_light);
}

.cabinet_menu_element_first {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
}

.cabinet_menu_element_last {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
}

.cabinet_total_card_link {
    color: black;
    text-decoration: none;
    text-transform: capitalize;
}

.cabinet_total_card_link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cabinet_total_card_link_dark {
    color: white;
}

.cabinet_input {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border: 1px solid var(--color_nice_brown);
    border-radius: 0.5rem;
    background-color: var(--color_main_light);
    color: var(--color_nice_brown);
}

.cabinet_input_dark {
    background-color: #908D96;
    color: white;
    border: 1px solid transparent;
}

.cabinet_header_gap {
    padding: 0.4rem;
}

.cabinet_body_internal {
    padding: 2rem;
}

.cabinet_paragraph {
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.cabinet_back_to_menu_wrapper {
    margin: 1rem;
}

.cabinet_warning_area {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.cabinet_warning_text {
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
    background-color: rgb(164, 90, 82);
    color: white;
    box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
}

@media screen and (min-width: 48rem) {
    .cabinet_area {
        display: flex;
        flex-direction: row;
    }

    .cabinet_menu_hidable {
        display: block;
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
        width: 25%;
        font-size: 1rem;
        margin-bottom: 0rem;
    }

    .cabinet_menu_mobile {
        display: none;
    }

    .cabinet_body {
        box-shadow: 0 1px 15px 1px rgba(52, 40, 104, .08);
        width: 73%;
    }

    .cabinet_back_to_menu_wrapper {
        display: none;
    }
}