:root {
  --header_color_background_light: #f5f5f5;
  --header_color_background_dark: var(--color_main_dark);

  --header_color_font_light: black;
  --header_color_font_dark: white;

  --header_color_mobile_burger_button: #616161;
}


.header {
    background-color: var(--header_color_background_light);
    color: var(--header_color_background_light);
    height: 4rem;
    padding-top: 2rem;
}

.header_dark {
    background-color: var(--header_color_background_dark);
}

.header a {
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 2;
}

.header img {
    z-index: 2;
    width: 20px;
    height: 20px;
}

.header_grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_logo_and_selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_logo_letters {
    height: 100%;
    color: var(--header_color_font_light);
    margin: 0 0.6rem;
}

.header_logo_letters_dark {
    color: var(--header_color_font_dark);
}

#header_menu_checkbox {
    opacity: 0;
    z-index: 2;
    height: 100%;
}

#header_menu_checkbox:checked + .header_menu_checkbox_label > span {
    transform: rotate(45deg);
}

#header_menu_checkbox:checked + .header_menu_checkbox_label > span::before {
    top: 0;
    transform: rotate(0deg);
}

#header_menu_checkbox:checked + .header_menu_checkbox_label > span::after {
    top: 0;
    transform: rotate(90deg);
}

.header_menu_checkbox_label {
    position: absolute;
    top: 49px;
    right: 20px;
    width: 26px;
    height: 19px;
    cursor: pointer;
    z-index: 2;
}

.header_menu_checkbox_label > span,
.header_menu_checkbox_label > span::before,
.header_menu_checkbox_label > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--header_color_mobile_burger_button);
    transition-duration: .25s;
}

.header_menu_checkbox_label > span::before {
    content: '';
    top: -8px;
}

.header_menu_checkbox_label > span::after {
    content: '';
    top: 8px;
}

.header_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    height: 22rem;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: var(--header_color_background_light);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05);
    padding: 6rem 1rem 2rem;
    top: -1rem;
    visibility: hidden;
    align-items: center;
    transition: all 200ms cubic-bezier(.785, .135, .15, .86);
    font-size: 1.2rem;
    z-index: 1;
}

.header_menu_dark {
    background-color: var(--header_color_background_dark);
}

.header_menu a {
    background-color: var(--color_main_light);
    border: 2px solid var(--color_nice_brown);
    border-radius: 30px;
    box-shadow: var(--color_nice_brown) 4px 4px 0 0;
    color: var(--color_nice_brown);
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    width: 30%;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.header_menu_dark a {
    box-shadow: none
}

.header_menu a:hover {
    text-decoration: underline;
}

#header_menu_checkbox:checked ~ .header_menu {
    opacity: 1;
    top: 0;
    visibility: visible;
}

.header_switch input {
    display: none;
}

.header_switch {
    margin-left: 1rem;
    width: 60px;
    height: 28px;
    transform: translateY(5%);
    z-index: 10;
}

.header_slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #777, 0 0 4px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: 0.2s;
}

.header_slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    transition: 0.2s;
}

input:checked + .header_slider:before {
    transform: translateX(30px);
    background-color: limeGreen;
}

input:checked + .header_slider {
    box-shadow: 0 0 0 2px limeGreen, 0 0 8px limeGreen;
}

.header_logo_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.header_logo_icon img {
    -moz-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
    width: 100%;
    height: 100%;
    border-radius: 0.5rem 40rem 40rem 40rem;
}

@media screen and (min-width: 48rem) {
    .header_menu {
        height: auto;
        background-color: transparent;
        box-shadow: none;
        flex-direction: row;
        opacity: 1;
        padding: 0;
        position: static;
        visibility: visible;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        width: 60%;
    }

    .header_menu a {
        background-color: transparent;
        width: auto;
        border: none;
        border-radius: 0;
        box-shadow: none;
        color: black;
        font-weight: 500;
        font-size: 1rem;
        padding: 0;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: none;
    }

    .header_menu_dark a {
        color: white;
    }

    .header_menu_checkbox_label {
        display: none;
    }
}

@media screen and (min-width: 64rem) {
    .header_menu {
        font-size: 1rem;
    }
}

@media screen and (min-width: 75rem) {
    .header_menu {
        font-size: 1rem;
    }
}